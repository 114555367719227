<template>
  <div class="line-list container">
    <div class="title-box">
      <a href="/storage"
        ><font-awesome-icon icon="fa-solid fa-chevron-left" />내 저장소</a
      >
      <h2>LINE</h2>
      <b-button class="sharebtn" @click="onShare()"
        ><font-awesome-icon icon="fa-solid fa-arrow-up-from-bracket"
      /></b-button>

    </div>
    <div class="filter">
      <ul>
        <li @click="typeFilter($event, '')" class="types on">전체</li>
        <li @click="typeFilter($event, 'text')" class="types ">텍스트</li>
        <li @click="typeFilter($event, 'image')" class="types ">이미지</li>
        <li @click="typeFilter($event, 'video')" class="types ">비디오</li>
        <li @click="typeFilter($event, 'audio')" class="types ">오디오</li>
        <li @click="typeFilter($event, 'etc')" class="types ">기타</li>
      </ul>
    </div>
    <div v-if="line_msg_count === 0 && users.length === 0" class="no-content">
      <h2>
        아직 저장된 <br />
        메세지가 없습니다!
      </h2>
      <p>캔디 채널을 추가하고 메세지를 보내주세요.</p>
      <a href="https://lin.ee/LlL6YfA" class="line">
        <span class="logo">
          <img
            src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/logo/Line_app_logo.png"
            alt="라인"
          />
        </span>
        <span>LINE</span>
      </a>
    </div>
    <div v-else>

    </div>
    <div class="msg-wrap">
      <div class="msg-board" v-for="user in filteredList" v-bind:key="user._id">
        <!--      <img class="card-img-top" src="..." alt="Card image cap">-->
        <div v-if="user.message.type === 'text'" class="msg-card text">
          <div class="desc">
            <p class="type">
              <font-awesome-icon icon="fa-regular fa-file-lines" />
            </p>
            <p class="info">
              <span class="date">
                {{ new Date(user.timestamp) | dateFormat("YYYY. MM. DD") }}
              </span>
              <span class="size"> </span>
            </p>
          </div>
          <div class="content">
            <p>
              {{ user.message.text }}
            </p>
          </div>
          <!--        <a href="#" class="btn btn-primary">Go somewhere</a>-->
        </div>

        <div v-else-if="user.message.type === 'image'" class="msg-card image">
          <div class="desc">
            <p class="type">
              <font-awesome-icon icon="fa-regular fa-file-image" />
            </p>
            <p class="info">
              {{ new Date(user.timestamp) | dateFormat("YYYY. M. D") }}
              <span class="size">{{
                user.message.object.object_size_mb
                  | fileSize(user.message.object.object_size_mb)
              }}</span>
            </p>
          </div>
          <div class="content">
            <img
              :src="
                `https://kr.object.ncloudstorage.com/candiy-bucket/line/${lineUserId}/${user.message.object.object_name}`
              "
              alt=""
            /><!-- img source -->
          </div>
          <!--        <a href="#" class="btn btn-primary">Go somewhere</a>-->
        </div>
        <div v-else-if="user.message.type === 'audio'" class="msg-card audio">
          <div class="desc">
            <p class="type">
              <font-awesome-icon icon="fa-regular fa-file-audio" />
            </p>
            <p class="info">
              {{ new Date(user.timestamp) | dateFormat("YYYY. M. D") }}
              <span class="size">{{
                user.message.object.object_size_mb
                  | fileSize(user.message.object.object_size_mb)
              }}</span>
            </p>
          </div>
          <div
            class="content"
            :id="`audctrl${user.message.id}`"
            @click="
              play(`audio${user.message.id}`, `audctrl${user.message.id}`)
            "
          >
            <font-awesome-icon
              icon="fa-regular fa-file-audio"
              style="font-size: 90px;color: rgba(0,0,0,0.4);"
            />
            <div class="button">
              <span class="play on"
                ><font-awesome-icon icon="fa-solid fa-play"
              /></span>
              <span class="pause" style="display: none;"
                ><font-awesome-icon icon="fa-solid fa-pause"
              /></span>
            </div>
            <div class="timeline">
              <div class="bar">
                <div class="inner"></div>
              </div>
            </div>
            <audio
              :src="
                `https://kr.object.ncloudstorage.com/candiy-bucket/line/${lineUserId}/${user.message.object.object_name}`
              "
              controls
              muted
              :id="`audio${user.message.id}`"
            ></audio
            ><!-- aud source -->
          </div>
          <!--        <a href="#" class="btn btn-primary">Go somewhere</a>-->
        </div>
        <div v-else-if="user.message.type === 'video'" class="msg-card video">
          <div class="desc">
            <p class="type">
              <font-awesome-icon icon="fa-regular fa-file-video" />
            </p>
            <p class="info">
              {{ new Date(user.timestamp) | dateFormat("YYYY. M. D") }}
              <span class="size">{{
                user.message.object.object_size_mb
                  | fileSize(user.message.object.object_size_mb)
              }}</span>
            </p>
          </div>
          <div class="content">
            <video
              :src="
                `https://kr.object.ncloudstorage.com/candiy-bucket/line/${lineUserId}/${user.message.object.object_name}`
              "
              id="video"
              muted
              controls
            ></video
            ><!-- vid source -->
          </div>
          <!--        <a href="#" class="btn btn-primary">Go somewhere</a>-->
        </div>
        <div v-else class="msg-card etc">
          <div class="desc">
            <p class="type"><font-awesome-icon icon="fa-regular fa-file" /></p>
            <p class="info">
              {{ new Date(user.timestamp) | dateFormat("YYYY. M. D") }}
              <span class="size">{{
                user.message.object.object_size_mb
                  | fileSize(user.message.object.object_size_mb)
              }}</span>
            </p>
          </div>
          <div class="content">
            <div>
              {{ user.message.type }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="datashare">
      <div class="bg" @click="onShare()"></div>
      <div class="share">
        <h4>데이터 공유하기</h4>
        <p>데이터를 공유하면 리워드를 받을 수 있어요!</p>
        <div class="title-btn">
          <h5>
            주식회사 사각
            <p
              style="margin:0; text-align: left;font-size: 14px;color: #FB5C5C;margin-top: 5px;"
            >
              500 포인트
            </p>
          </h5>
          <button class="" @click="onShare()">공유</button>
        </div>
        <div class="share-detail">
          <h6>데이터 보유 기간</h6>
          <p>2023.02.20 ~ 2024.02.19 <span>(365일)</span></p>
          <h6>목적</h6>
          <p>캔디 사용자 통계 분석용</p>
        </div>

        <h6 style="text-align: left;font-weight: 600;">거래 데이터</h6>
        <ul>
          <li @click="onToggle(1)">
            <span>텍스트 파일</span>
            <p><span></span></p>
          </li>
          <li @click="onToggle(2)">
            <span>미디어 파일</span>
            <p><span></span></p>
          </li>
          <li @click="onToggle(3)">
            <span>기타 파일</span>
            <p><span></span></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "LineList",
  setup() {},
  data() {
    return {
      isActive: "",
      search: "",
      line_msg_count:0
      // saerch_input:"",
      // lineUserId: null
      // clientId: '',
      // clientSecret: '',
      // callbackUri: ''
    };
  },
  filters: {
    dateFormat: function(val, format) {
      return moment(val).format(format);
    },
    toFixed: function(val, num) {
      return parseFloat(val).toFixed(num);
    },
    fileSize: function(val) {
      var x = val * 1024 * 1024;
      var s = ["bytes", "kB", "MB", "GB", "TB", "PB"];
      var e = Math.floor(Math.log(x) / Math.log(1024));
      return (x / Math.pow(1024, e)).toFixed(2) + " " + s[e];
    },
    comma: function(val) {
      return String(Math.round(val)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.line.users,
      lineUserId: (state) => state.line.lineUserId,
    }),
    filteredList() {
      return this.users.filter((user) => {
        return user.message.type
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  created() {
    // if (localStorage.getItem("access_token") !== null) {
    //   var access_token = localStorage.getItem("access_token")
    //
    //   //console.log("access_token:"+access_token)
    //   const {data} = await this.getProfile(access_token)
    //   this.lineUserId = data.userId
    // }

    //console.log("line_user_id:" + this.lineUserId);

    this.$store.dispatch("line/loadUsers", this.lineUserId);

    if (localStorage.getItem("line_msg_count") != null) {
      this.line_msg_count = localStorage.getItem("line_msg_count")
    }

    //console.log("files:"+JSON.stringify(this.files));
  },
  methods: {
    typeFilter(type, value) {
      let types = document.querySelectorAll(".types");
      types.forEach((element) => element.classList.remove("on"));

      type.target.classList.add("on");

      // let input_search = document.querySelector("#search_input");
      //
      // input_search.value = value;
      this.search = value;
      console.log(value);
      // some code to filter users
    },
    onShare() {
      let datashare = document.querySelector(".datashare");
      let lineview = document.querySelector(".line-view ");
      if (!datashare.classList.contains("on")) {
        datashare.classList.add("on");
        lineview.style.height = "70vh";
        lineview.style.overflow = "hidden";
      } else {
        datashare.classList.remove("on");
        lineview.style.height = "fit-content";
        lineview.style.overflow = "auto";
      }
    },
    onToggle(idx) {
      let toggle = document.querySelector(
        `.datashare .share ul li:nth-child(${idx}) p`
      );
      toggle.classList.toggle("on");
    },
    play(type, typebox) {
      const media = document.querySelector(`#${type}`);
      // const contBox = document.querySelector(`#${typebox}`);
      console.log(typebox);
      console.log(type);
      // // 마우스 호버 이벤트...fade in/out
      // contBox.addEventListener("mouseleave", function() {
      //   document.querySelectorAll(`#${typebox} span`).forEach((item) => {
      //     item.style.opacity = 0;
      //   });
      // });
      // contBox.addEventListener("mouseenter", function() {
      //   document.querySelectorAll(`#${typebox} span`).forEach((item) => {
      //     item.style.opacity = 1;
      //   });
      // });
      // set the pause button to display:none by default
      // update the progress bar
      if (media.paused) {
        document.querySelector(`#${typebox} .play`).style.display = "none";
        document.querySelector(`#${typebox} .pause`).style.display =
          "inline-block";
        media.play();
      } else {
        document.querySelector(`#${typebox} .play`).style.display =
          "inline-block";
        document.querySelector(`#${typebox} .pause`).style.display = "none";
        media.pause();
      }
      media.addEventListener("timeupdate", () => {
        let curr = (media.currentTime / media.duration) * 100;
        if (media.ended) {
          document.querySelector(`#${typebox} .play`).style.display =
            "inline-block";
          document.querySelector(`#${typebox} .pause`).style.display = "none";
        }
        document.querySelector(`#${typebox} .inner`).style.width = `${curr}%`;
      });
    },
  },
};
</script>
<style>
/* if no content */
.no-content {
  width: 90%;
  margin: auto;
  text-align: center;
  padding-top: 80px;
}
.no-content h2 {
  word-break: keep-all;
  margin-bottom: 10px;
  font-weight: 600;
  color: #191919;
}
.no-content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 60px;
}
.no-content a {
  display: block;
  width: 250px;
  height: 50px;
  line-height: 50px;
  margin: auto;
  margin-bottom: 20px;
  color: #191919;
  display: flex;
  align-items: center;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}
.no-content a span {
  vertical-align: middle;
  display: block;
  text-align: center;
  width: 100%;
}
.no-content a span.logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  overflow: hidden;
  position: relative;
  font-size: 1rem;
  padding: 0 20px;
}
.no-content a span img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.no-content a.line {
  background-color: #06c755;
  color: #fff;
}
/*  */
.line-list.container {
  padding: 0;
}
/* 타이틀 */
.title-box {
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  width: 93%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 50px;
}
.title-box a {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #007bff;
  font-weight: 400;
}
.title-box a svg {
}
.title-box h2 {
  font-size: 18px;
  margin: 0;
  line-height: 6.9444vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
/* 공유버튼 */
.title-box .sharebtn {
  margin: 0;
  background: none;
  border: none;
  padding: 0 2.7778vw;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.title-box .sharebtn svg {
  color: #333;
}
/* 필터 */
.filter {
  display: flex;
  width: 90%;
  margin: auto;
  padding: 15px 0px;
  justify-content: space-between;
  max-width: 640px;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.filter::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filter {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*  */
.filter ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  min-width: fit-content;
  width: 100%;
}
.filter ul li {
  margin-right: 20px;
  display: inline-block;
  white-space: nowrap;
  color: #d2d2d2;
  font-weight: 400;
  font-size: 18px;
}
.filter ul li:last-child {
  margin-right: 0;
}
.filter ul li.on {
  border-color: #5ebdeb;
  color: #5ebdeb;
  font-weight: 900;
}

/* 메세지 보드 */
.msg-wrap {
  margin-top: 4.1667vw;
  --auto-grid-min-size: 330px;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.msg-board {
  margin-top: -0.2778vw;
}
.msg-board .msg-card {
  width: 92%;
  border: none;
  height: 17vw;
  max-width: 380px;
  min-height: 170px;
  margin: auto;
  margin-bottom: 3vw;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.1);
}
.msg-board .msg-card .desc {
  width: 30%;
  padding: 10px;
  position: relative;
}
.msg-board .msg-card .desc p.type {
  background: #fff;
  display: inline-block;
  text-align: center;
  font-size: 36px;
  margin: 0;

  /* border: 0.2778vw solid #000; */
}
/* 데이터 타입 태그 */
.msg-board .msg-card.text .desc p.type {
  color: #ffa753;
}
.msg-board .msg-card.video .desc p.type {
  color: #2ec33a;
}
.msg-board .msg-card.image .desc p.type {
  color: #fb5c5c;
}
.msg-board .msg-card.audio .desc p.type {
  color: #b09fff;
}
.msg-board .msg-card.etc .desc p.type {
  color: #959595;
}
/* 데이터 정보 */
.msg-board .msg-card .desc p.info {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.msg-board .msg-card .desc p.info span {
  padding-left: 0;
  font-size: 14px;
}
.msg-board .msg-card .desc p.info span.date {
  display: block;
}
.msg-board .msg-card .desc p.info span.size {
  display: block;
}
.msg-board .msg-card .content {
  width: 70%;
  background: #666;
  overflow: hidden;
  box-sizing: border-box;
  margin: 7px;
  border-radius: 5px;
}
.msg-board .msg-card.video .content,
.msg-board .msg-card.image .content,
.msg-board .msg-card.audio .content {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 데이터별 */
.msg-board .msg-card.text .content p,
.msg-board .msg-card.etc .content div {
  background: #efefef;
  width: 100%;
  height: 100%;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-align: left;
}
.msg-board .msg-card.text .content p {
  word-break: break-all;
  margin: 0;
}
.msg-board .msg-card.video .content video {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.msg-board .msg-card.image .content img {
  height: 100%;
}
.msg-board .msg-card.audio .content audio {
  height: 0;
  width: 0;
}

/* audio control */
.msg-board .msg-card.audio .content {
  position: relative;
}
.msg-board .msg-card.video .content {
  position: relative;
}
.content .button {
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content .button span {
  font-size: 40px;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.content .timeline {
  flex: 1;
  display: flex;
  align-items: center;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.content .timeline .bar {
  background: rgba(255, 255, 255, 0.2);
  height: 15px;
  flex: 1;
}
.content .timeline .bar .inner {
  background: rgb(255, 255, 255, 0.7);
  width: 0%;
  height: 100%;
}

/* 공유하기 */
.datashare {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}
.datashare.on {
  display: block;
  opacity: 1;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.3);
}
.datashare .bg {
  background: transparent;
  width: 100%;
  height: 100%;
}
.datashare .share {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background: #fff;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.07);
  padding: 30px 30px 40px;
}

.datashare .share h4 {
  line-height: 1.4;
  margin: 0;
  margin-bottom: 1.3889vw;
  font-weight: 600;
  font-size: 18px;
}
.datashare .share > p {
  line-height: 1.2;
  font-size: 15px;
  margin: 0;
  word-break: keep-all;
}
.datashare .share .title-btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #999;
  padding: 5px 0;
  margin: 20px 0 20px;
}
.datashare .share .title-btn h5 {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 600;
}
.datashare .share .title-btn button {
  margin: 0;
  padding: 3px 0;
  border: none;
  outline: none;
  width: 60px;
  height: fit-content;
  border-radius: 5px;
  background-color: #5ebdeb;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.datashare .share ul {
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 5px;
}
.datashare .share ul li {
  margin: auto;
  height: 11.1111vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.3889vw;
  padding: 0 2.7778vw;
  border: none;
  background: #efefef;
  border-radius: 5px;
}
.datashare .share ul li > span {
  display: block;
  text-align: left;
  font-size: 14px;
}
.datashare .share ul li p {
  display: block;
  width: 11.1111vw;
  background: #999;
  border-radius: 5.5556vw;
  height: 5.5556vw;
  box-sizing: border-box;
  margin-top: 4.1667vw;
  position: relative;
}
.datashare .share ul li p span {
  display: block;
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0.2778vw;
  left: 0.2778vw;
}
.datashare .share ul li p.on {
  background: #00c853;
}
.datashare .share ul li p.on span {
  left: auto;
  right: 0.2778vw;
}
.datashare .share button {
  width: 22.2222vw;
  margin: 5.5556vw auto 0;
}
.share-detail {
  text-align: left;
  margin-bottom: 10px;
}
h6 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 0;
}
.share-detail p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}
.share-detail p span {
  color: #fb5c5c;
}
</style>
